// assets
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons';

// icons
const icons = {
    UpSquareOutlined,
    DownSquareOutlined
};

// ==============================|| MENU ITEMS - TOP GAINERS/LOSERS PAGE ||============================== //

const gainers_losers = {
    id: 'gainers_losers',
    title: 'Top Gainers/Losers',
    type: 'group',
    children: [
        {
            id: 'top-gainers',
            title: 'Top Gainers',
            type: 'item',
            url: '/top-gainers',
            icon: icons.UpSquareOutlined,
            breadcrumbs: false
        },
        {
            id: 'top-losers',
            title: 'Top Losers',
            type: 'item',
            url: '/top-losers',
            icon: icons.DownSquareOutlined,
            breadcrumbs: false
        }
    ]
};

export default gainers_losers;
