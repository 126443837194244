import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";

// render - dashboard
const DashboardSimple = Loadable(lazy(() => import("pages/dashboard_simple")));

// render - screeners
const LargeCapGapUp = Loadable(lazy(() => import("pages/large_cap_gap_up")));
const LargeCapGapDown = Loadable(
  lazy(() => import("pages/large_cap_gap_down"))
);

// render - top gainers/losers
const TopGainers = Loadable(lazy(() => import("pages/top_gainers")));
const TopLosers = Loadable(lazy(() => import("pages/top_losers")));

// render - top actives
const TopTurnovers = Loadable(lazy(() => import("pages/top_turnovers")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardSimple />,
    },
    {
      path: "/large-cap-gap-up",
      element: <LargeCapGapUp />,
    },
    {
      path: "/large-cap-gap-down",
      element: <LargeCapGapDown />,
    },
    {
      path: "/top-gainers",
      element: <TopGainers />,
    },
    {
      path: "/top-losers",
      element: <TopLosers />,
    },
    {
      path: "/top-turnovers",
      element: <TopTurnovers />,
    },
  ],
};

export default MainRoutes;
