// assets
import { SyncOutlined, ArrowDownOutlined } from "@ant-design/icons";

// icons
const icons = {
  SyncOutlined,
  ArrowDownOutlined,
};

// ==============================|| MENU ITEMS - TOP ACTIVES PAGE ||============================== //

const top_actives = {
  id: "top_actives",
  title: "Most Actives",
  type: "group",
  children: [
    {
      id: "top-turnovers",
      title: "Top Turnovers",
      type: "item",
      url: "/top-turnovers",
      icon: icons.SyncOutlined,
      breadcrumbs: false,
    },
  ],
};

export default top_actives;
