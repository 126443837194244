// project import
import dashboard from './dashboard_simple';
import screeners from './screeners';
import gainers_losers from './gainers_losers';
import top_actives from './top_actives';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, screeners, gainers_losers, top_actives]
};

export default menuItems;
